import { Link } from "gatsby";
import * as React from "react";
import {
  Button, Container,
  Form,
  Row
} from "react-bootstrap";
import Layout from "../components/Layout";

export default function BogoBrandPage() {
  return (
    <Layout>
      <main>
        <Container>
          <section>
            <div className="col-xl-5 col-lg-5 col-md-12 pt-3 mx-auto text-center">
              <h1 className="fs-48 fx-blod mb-4">BOGO Brand Ambassador</h1>
              <img src="../img/influencer-register.svg" alt="register" />
              <h5 className="fw-bold fs-36 mt-4">Submit Your Interest</h5>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 pb-5 mx-auto text-center">
              <Form className="text-start mt-5">
                <Form.Group controlId="formGridName" className="mb-4">
                  <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                    Full name
                  </Form.Label>
                  <Form.Control type="text" />
                </Form.Group>

                <Form.Group controlId="formGridEmail" className="mb-4">
                  <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                    E-mail address
                  </Form.Label>
                  <Form.Control type="email" />
                </Form.Group>
                <p className="fw-bold text-center mb-1">Your social channels</p>
                <p className="text-center"> Please fill in at least one</p>
                <Form.Group controlId="formGridInstagram" className="mb-4">
                  <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                    Instagram username
                  </Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
                <Form.Group controlId="formGridYouTube" className="mb-4">
                  <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                    YouTube channel
                  </Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
                <Button
                  variant="primary"
                  className="w-100 fs-14 fw-bold h-52"
                  type="submit"
                >
                  Submit
                </Button>
              </Form>
            </div>
          </section>
        </Container>
        <Container fluid={true} className="py-xl-5">
          <section className="Light-bg my-5">
            <Row>
              <div className="col-xl-6 col-lg-6 col-md-12 ps-0 bg-last-section">
                <img src="../img/Onboarding.svg" alt="bg" />
              </div>
              <div className="col-xl-5 col-lg-6 col-md-12 pt-xl-5 pt-lg-5">
                <h4 className="fs-40 fw-bold mb-5">Are you a merchant?</h4>
                <h5 className="bogo-par fw-normal lh-32 mb-5 fw-airlight pe-5 me-5">
                  Increase foot traffic and sustainably grow your profitability
                  with The BOGO App’s Dynamic Campaign Management™ system
                </h5>
                <Link to="#" className="fs-18 link-color pb-2">
                  BOGO for Business{" "}
                 <img src="../img/arrow-right.svg" alt="arrow right" className="ms-3" />
                </Link>
              </div>
            </Row>
          </section>
        </Container>
      </main>
    </Layout>
  );
}
